import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME, setRandomListings } from './LandingPage.duck';

import css from './LandingPage.module.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const SectionFeaturesCustom = loadable(() =>
  import(
    /* webpackChunkName: "SectionFeaturesCustom" */ '../PageBuilder/SectionBuilder/SectionFeaturesCustom'
  )
);

export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error, listings, onSetRandomListings,random12Listings } = props;

  return (
    <PageBuilder
      className={css.root}
      pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      settings={{
        hideTopBarSearch: true,
      }}
      options={{
        sectionComponents: {
          features: {
            component: SectionFeaturesCustom,
            props: { listings, onSetRandomListings,random12Listings },
          },
        },
      }}
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { currentPageResultIds,random12Listings } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error, listings,random12Listings };
};

const mapDispatchToProps = dispatch => ({
  onSetRandomListings: listings => dispatch(setRandomListings(listings)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LandingPageComponent);

export default LandingPage;
